import styled from "styled-components";
import { Button } from "../styles/styled-components/Button";
import Fade from "../styles/styled-components/Fade";
import { Show } from "./Show";
import { StoryText } from "./StoryText";

const TwoButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 200px;
  @media only screen and (max-width: 475px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

const Wrapper = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

export function ChoicesBox(props) {
  const {
    timeOfDay,
    choiceOneClick,
    choiceTwoClick,
    choiceOneLabel,
    choiceTwoLabel,
    text,
    fadeStyle,
  } = props;
  return (
    <Fade fadeStyle={fadeStyle}>
      <Wrapper>
        <StoryText
          speaker={text.speaker}
          text={text.text}
          timeOfDay={timeOfDay}
        />
        <TwoButtonContainer>
          <Button
            timeOfDay={timeOfDay}
            onClick={choiceOneClick}
            label={choiceOneLabel}
          />
          <Show when={choiceTwoLabel}>
            <Button
              timeOfDay={timeOfDay}
              onClick={choiceTwoClick}
              label={choiceTwoLabel}
            />
          </Show>
        </TwoButtonContainer>
      </Wrapper>
    </Fade>
  );
}
