import styled from "styled-components";
import { HasteLogo } from "./HasteLogo";

const StickyBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -62px;
  margin-bottom: 50px;
`;

export function Footer() {
  return (
    <StickyBottom>
      <HasteLogo />
    </StickyBottom>
  );
}
