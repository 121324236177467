import { Menu as MenuInner, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import styled, { keyframes } from "styled-components";
import menuIcon from "../assets/images/hamburger-menu.svg";
import closeIcon from "../assets/images/close.svg";
import { menuSelector, menuItemSelector } from "@szhsin/react-menu/style-utils";
import "@szhsin/react-menu/dist/core.css";
import { colors } from "../styles/colors";

const menuShow = keyframes`
  from {
    opacity: 0;
  }
`;
const menuHide = keyframes`
  to {
    opacity: 0;
  }
`;

const Menu = styled(MenuInner)`
  ${menuSelector.name} {
    font-size: 0.925rem;
    user-select: none;
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 6px;
    min-width: 10rem;
    background: ${colors.almost_black};
    color: white;
    text-align: center;
    font-weight: bold;
  }

  ${menuSelector.stateOpening} {
    animation: ${menuShow} 0.15s ease-out;
  }

  // NOTE: animation-fill-mode: forwards is required to
  // prevent flickering with React 18 createRoot()
  ${menuSelector.stateClosing} {
    animation: ${menuHide} 0.2s ease-out forwards;
  }

  ${menuItemSelector.name} {
    padding: 1rem;
  }

  ${menuItemSelector.hover} {
    color: #fff;
    background-color: ${colors.black};
  }
`;

const HamburgerIcon = styled.button`
  background: url(${menuIcon}) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  width: 30px;
  border: none;
`;

const CloseIcon = styled(HamburgerIcon)`
  background: url(${closeIcon}) no-repeat top left;
`;

export function EpisodeMenu(props) {
  const { onSelected } = props;
  return (
    <Menu
      menuButton={({ open }) => (open ? <CloseIcon /> : <HamburgerIcon />)}
      direction="bottom"
      transition
      align="center"
      onItemClick={onSelected}
    >
      <MenuItem value={1}>Episode 1</MenuItem>
      <MenuItem value={2}>Episode 2</MenuItem>
      <MenuItem value={3}>Episode 3</MenuItem>
    </Menu>
  );
}
