export function runtimeEstimate(storyBoard) {
  let totalSeconds = 0;
  for (const scene of storyBoard.scenes) {
    if (scene.progression) {
      for (const step of scene.progression) {
        const optimalSeconds = optimal(step.text.length) + 2;
        totalSeconds += optimalSeconds;
      }
    }
  }
  const estimateRuntime = Math.round(totalSeconds / 60 / 5) * 5;
  return `${estimateRuntime} min runtime`;
}

function optimal(charCount) {
  let seconds = Math.round(charCount / 15);
  return seconds < 2.5 ? 2.5 : seconds;
}
