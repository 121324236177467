import styled from "styled-components";
import { colors } from "../colors";

export const P = styled.p`
  margin: 0;
  font-weight: 200;
  font-size: 2rem;
  color: ${colors.gray};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const Explainer = styled(P)`
  margin-top: 0.5rem;
  font-size: 0.7rem;
  font-weight: 800;
  cursor: pointer;
`;

export const ExplainerHidden = styled(Explainer)`
  visibility: hidden;
`;
