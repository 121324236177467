import { Footer } from "../Footer";
import { HeadingText } from "../../styles/styled-components/HeadingText";
import { Input } from "../../styles/styled-components/Input";
import { Button } from "../../styles/styled-components/Button";
import styled from "styled-components";
import { colors } from "../../styles/colors";

const PasswordProtection = styled.div`
  background-color: ${colors.black};
  color: ${colors.white};
  min-height: 100vh;
  max-heigth: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 100vw;};
  position: relative;
`;

export function PasswordPage(props) {
  const { checkAuth, keyPress } = props;

  return (
    <PasswordProtection>
      <HeadingText>Enter VIP Password</HeadingText>
      <Input id="password-input" type="password" onKeyPress={keyPress} />
      <Button
        timeOfDay="night"
        label="Submit"
        onClick={checkAuth}
        type="submit"
        form="password-form"
      />
      <Footer />
    </PasswordProtection>
  );
}
