export class Sleepy {
  for(seconds) {
    if (!seconds) return;
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
  }
  optimal(charCount, func) {
    let timeInMs = Math.round((charCount / 15) * 1000);
    timeInMs = timeInMs < 2500 ? 2500 : timeInMs;
    return new Promise((resolve) => setTimeout(resolve, func(timeInMs)));
  }
}
