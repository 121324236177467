import Typewriter from "typewriter-effect";
import { Footer } from "../Footer";
import { TypeWriterWrapper } from "../../styles/styled-components/TypeWriterWrapper";
import { MainBody } from "../../styles/styled-components/MainBody";
import { VoyageLogo } from "../VoyageLogo";
import { useState } from "react";
import { HeadingText } from "../../styles/styled-components/HeadingText";

export function ClickAnywhere(props) {
  const { onCanvasClick, timeOfDay } = props;
  const [ready, setReady] = useState(false);
  return (
    <div
      onClick={onCanvasClick}
      style={ready ? { cursor: "pointer" } : undefined}
    >
      <MainBody timeOfDay={timeOfDay}>
        <VoyageLogo />
        <HeadingText>Voyage</HeadingText>
        <TypeWriterWrapper>
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .pauseFor(500)
                .typeString("Create immersive experiences.")
                .pauseFor(500)
                .deleteAll()
                .pauseFor(500)
                .typeString("Dream up a Voyage series.")
                .pauseFor(500)
                .deleteAll()
                .typeString("Create a gift for a loved one.")
                .pauseFor(500)
                .deleteAll()
                .typeString("Build an educational tool.")
                .pauseFor(500)
                .deleteAll()
                .typeString("Tap or click anywhere.")
                .callFunction(() => {
                  setReady(true);
                })
                .start();
            }}
          />
        </TypeWriterWrapper>
        <Footer />
      </MainBody>
    </div>
  );
}
