import { Howl } from "howler";

export class AudioManager {
  async fadeAudioOut(src) {
    if (!src || !src.instance) {
      return;
    }
    const instance = src.instance;

    const fadeOutTimeInMs = src.fadeOutTime ? src.fadeOutTime * 1000 : 1000;
    const currentVolume = instance.volume();

    instance.on("fade", () => {
      instance.stop();
      instance.unload();
    });

    instance.fade(currentVolume, 0, fadeOutTimeInMs);
    return;
  }

  async fadeAudioIn(src) {
    if (!src || !src.file) {
      return;
    }
    const instance = new Howl({
      src: [src.file],
      html5: true,
    });

    const fadeInTimeInMs = src.fadeInTime ? src.fadeInTime * 1000 : 1000;
    const loop = src.loop ? src.loop : false;
    const volume = src.volume ? src.volume : 0.6;
    instance.volume(0);
    instance.loop(loop);
    instance.play();
    instance.fade(0, volume, fadeInTimeInMs);
    if (src.duration) {
      setTimeout(() => {
        this.fadeAudioOut({ ...src, instance });
      }, src.duration * 1000);
    }
    return { ...src, instance };
  }

  stopAppropriateBackgroundMusic(instances) {
    for (const instance of instances) {
      this.fadeAudioOut(instance);
    }
  }

  stopAppropriateSoundEffects(instances) {
    for (const instance of instances) {
      this.fadeAudioOut(instance);
    }
  }
}
