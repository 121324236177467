import { useEffect, useState } from "react";
import { getStory } from "../../story/story";
import { ProgressionPage } from "./ProgressionPage";
import { StartPage } from "./StartPage";
import { ClickAnywhere } from "./ClickAnywhere";
import { AudioManager } from "../../utils/audio/AudioManager";
import { Sleepy } from "../../utils/sleep";
import { PasswordPage } from "./PasswordPage";
import axios from "axios";
const lambdaEndpoint =
  "https://wasu5pfhwvlpnq4cmuzkeglsya0agbgv.lambda-url.us-east-2.on.aws/";

const audio = new AudioManager();
const sleep = new Sleepy();

export function Main() {
  const [ready, setReady] = useState(false);
  const [choice, setChoice] = useState(0);
  const [scene, setScene] = useState({});
  const [fadeStyle, setFadeStyle] = useState("in");
  const [playing, setPlaying] = useState(false);
  const [progressing, setProgressing] = useState(false);
  const [text, setText] = useState({ speaker: "", text: "" });
  const [timeOfDay, setTimeOfDay] = useState("night");
  const [soundEffectInstances, setSoundEffectInstances] = useState([]);
  const [backgroundInstances, setBackgroundInstances] = useState([]);
  const [stopBackgroundMusic, setStopBackgroundMusic] = useState(false);
  const [stopSoundEffect, setStopSoundEffect] = useState(false);
  const [hideChoiceButton, setHideChoiceButton] = useState(false);
  const [ended, setEnded] = useState(false);
  const [endText, setEndText] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepIndex, setCurrentStepIndex] = useState(undefined);
  const [actionMessage, setActionMessage] = useState("");
  const [playSpeed, setPlaySpeed] = useState("normal");
  const [episodeSelected, setEpisodeSelected] = useState(undefined);
  const [storyBoard, setStoryBoard] = useState(undefined);
  const [authenticated, setAuthenticated] = useState(true);

  //***** 🪝 useEffect Hooks 🪝 ******* */

  useEffect(() => {
    if (ready || !storyBoard) return;
    const id = localStorage.getItem("sceneId");
    if (!id) return;
    setScene(getScene(id));
    setReady(true);
    setProgressing(true);
    setFadeStyle("story-in");
    setPlaying(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyBoard]);

  useEffect(() => {
    loadPage();
  }, []);

  useEffect(() => {
    if (!episodeSelected) return;
    async function updateStoryBoard() {
      setStoryBoard(await getStory(episodeSelected));
      localStorage.setItem("episode", episodeSelected);
    }
    updateStoryBoard();
    if (!ready) setEpisodeSelected(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodeSelected]);

  useEffect(() => {
    async function impersonateClick() {
      if (hideChoiceButton) {
        setProgressing(false);
        await sleep.for(sleepBasedOnSpeed(2));
        handleChoiceOneClick();
      }
    }
    impersonateClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideChoiceButton]);

  useEffect(() => {
    if (stopBackgroundMusic && backgroundInstances.length > 0) {
      stopAppropriateAudio({ stopBackground: true });
    }
    setStopBackgroundMusic(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopBackgroundMusic, backgroundInstances]);

  useEffect(() => {
    if (stopSoundEffect && soundEffectInstances.length > 0) {
      stopAppropriateAudio({ stopSoundEffect: true });
    }
    setStopSoundEffect(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopSoundEffect, soundEffectInstances]);

  useEffect(() => {
    if (!scene.progression) return;
    localStorage.setItem("sceneId", scene.id);
    async function handleSceneChange() {
      if (!scene.finished) {
        if (hideChoiceButton) {
          await sleep.for(sleepBasedOnSpeed(2));
        }
        await preScene(scene);
        setProgressing(true);
        setActionMessage("");
        if (scene.progression) {
          if (!currentStepIndex) {
            setCurrentStepIndex(10000); // something unlikley
          }
          await sleep.for(sleepBasedOnSpeed(1));
          setCurrentStepIndex(0);
        }
      }
    }

    handleSceneChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene]);

  useEffect(() => {
    if (scene.progression) {
      setCurrentStep(scene.progression[currentStepIndex]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex]);

  useEffect(() => {
    async function walkThroughEachStep() {
      if (currentStepIndex === undefined || currentStep === undefined) return;
      setText({
        speaker: currentStep.speaker ?? "",
        text: currentStep.text ?? "",
      });
      setFadeStyle("story-in");
      setProgressing(true);
      if (currentStep.timeOfDay) {
        setTimeOfDay(currentStep.timeOfDay);
      }
      if (currentStep.stopBackground) {
        setStopBackgroundMusic(true);
      }
      if (currentStep.stopSoundEffect) {
        setStopSoundEffect(true);
      }
      if (currentStep.soundEffect) {
        if (currentStep.soundEffect.delay)
          await sleep.for(sleepBasedOnSpeed(currentStep.soundEffect.delay));
        const instance = await audio.fadeAudioIn(currentStep.soundEffect);
        setSoundEffectInstances((prev) => [...prev, instance]);
      }

      if (currentStep.pause)
        await sleep.for(sleepBasedOnSpeed(currentStep.pause));

      if (currentStepIndex + 1 !== scene.progression.length) {
        await sleep.optimal(currentStep.text.length, sleepBasedOnSpeed);
        setFadeStyle("story-out");
        await sleep.for(sleepBasedOnSpeed(2));
      }

      setFadeStyle("story-in");

      if (currentStep.choiceOne?.hide) {
        setHideChoiceButton(true);
      }
      if (currentStep.end) {
        setFadeStyle("out");
        await sleep.for(sleepBasedOnSpeed(3));
        setFadeStyle("in");
        setEndText(currentStep.endText ? currentStep.endText : "");
        setEnded(true);
        setPlaying(false);
      }

      if (currentStepIndex + 1 === scene.progression.length) {
        setActionMessage("Rewatch scene");
        if (currentStep.end) {
          setProgressing(true);
        } else {
          setProgressing(false);
        }
        setCurrentStepIndex(currentStepIndex);
        setScene({ ...scene, finished: true });
        return;
      }

      setCurrentStepIndex(currentStepIndex + 1);
    }
    if (playing) {
      walkThroughEachStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    if (!choice) return;
    const theScene = getScene(choice);
    theScene.action = false;
    setScene(theScene);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choice]);

  useEffect(() => {
    if (!ended) return;
    localStorage.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ended]);

  //***** 👇 Helper Functions 👇 ******* */

  async function loadPage() {
    const episode = localStorage.getItem("episode");
    const id = localStorage.getItem("sceneId");
    if (!episode || !id) {
      setEpisodeSelected(1);
      return;
    }
    setEpisodeSelected(+episode);
  }

  function getScene(id) {
    const scene = storyBoard.scenes.filter((arr) => arr.id === id);
    return scene[0];
  }

  async function preScene(theScene) {
    let tod; //time of day
    if (theScene.delayStart) {
      await sleep.for(sleepBasedOnSpeed(theScene.delayStart));
    }
    if (theScene.backgroundMusic && !theScene.action) {
      playBackgroundMusic(theScene);
    }

    tod = theScene.timeOfDay ? theScene.timeOfDay : "night";
    setTimeOfDay(tod);

    setHideChoiceButton(false);
  }

  async function playBackgroundMusic(theScene) {
    let instance;
    if (theScene.backgroundMusic.delay) {
      setTimeout(async () => {
        instance = await audio.fadeAudioIn(theScene.backgroundMusic);
        setBackgroundInstances((prev) => [...prev, instance]);
      }, theScene.backgroundMusic.delay * 1000);
    } else {
      instance = await audio.fadeAudioIn(theScene.backgroundMusic);
      setBackgroundInstances((prev) => [...prev, instance]);
    }
  }

  function stopAppropriateAudio(userChoice) {
    if (userChoice.stopSoundEffect && soundEffectInstances.length) {
      setStopSoundEffect(true);
      audio.stopAppropriateSoundEffects(soundEffectInstances);
      setSoundEffectInstances([]);
    }
    if (userChoice.stopBackground && backgroundInstances.length) {
      setStopBackgroundMusic(true);
      audio.stopAppropriateBackgroundMusic(backgroundInstances);
      setBackgroundInstances([]);
    }
  }

  async function transitionToStartPage() {
    let instance = await audio.fadeAudioIn(getScene("start").backgroundMusic);
    setBackgroundInstances((prev) => [...prev, instance]);
    setReady(true);
  }

  async function handleChoiceOneClick() {
    setFadeStyle("out");
    await sleep.for(sleepBasedOnSpeed(2));
    setText({ speaker: "", text: "" });
    const userChoice = scene.progression[scene.progression.length - 1];
    setChoice(userChoice.choiceOne.id);
    stopAppropriateAudio(userChoice.choiceOne);
  }

  async function handleChoiceTwoClick() {
    setFadeStyle("out");
    await sleep.for(sleepBasedOnSpeed(2));
    setText({ speaker: "", text: "" });
    const userChoice = scene.progression[scene.progression.length - 1];
    setChoice(userChoice.choiceTwo.id);
    stopAppropriateAudio(userChoice.choiceTwo);
  }

  function handleActionClick() {
    setText({ speaker: "", text: "" });
    setActionMessage("");
    scene.finished = false;
    scene.action = true;
    setStopSoundEffect(true);
    setScene({ ...scene });
  }

  function handleSlowDownEnter() {
    if (playSpeed !== "slow") {
      setActionMessage("Slow down");
    }
  }

  function handleSlowDownLeave() {
    currentStepIndex + 1 === scene.progression.length
      ? setActionMessage("Rewatch scene")
      : setActionMessage("");
  }

  function handleSpeedUpEnter() {
    if (playSpeed !== "fast") {
      setActionMessage("Speed up");
    }
  }

  function handleSpeedUpLeave() {
    currentStepIndex + 1 === scene.progression.length
      ? setActionMessage("Rewatch scene")
      : setActionMessage("");
  }

  function handleSlowDownClick() {
    playSpeed === "fast" ? setPlaySpeed("normal") : setPlaySpeed("slow");
  }

  function handleSpeedUpClick() {
    playSpeed === "slow" ? setPlaySpeed("normal") : setPlaySpeed("fast");
  }

  function sleepBasedOnSpeed(input) {
    let factor;
    switch (playSpeed) {
      case "slow":
        factor = 2;
        break;
      case "normal":
        factor = 1;
        break;
      case "fast":
        factor = 0.5;
        break;

      default:
        factor = 1;
        break;
    }
    return factor * input;
  }

  function resetVoyageState() {
    loadPage();
    audio.stopAppropriateBackgroundMusic(backgroundInstances);
    audio.stopAppropriateSoundEffects(soundEffectInstances);
    setReady(false);
    setChoice(0);
    setScene({});
    setPlaying(false);
    setProgressing(false);
    setText({ speaker: "", text: "" });
    setTimeOfDay("night");
    setSoundEffectInstances([]);
    setBackgroundInstances([]);
    setHideChoiceButton(false);
    setEnded(false);
    setEndText("");
    setCurrentStep(0);
    setCurrentStepIndex(undefined);
    setActionMessage("");
    setPlaySpeed("normal");
    setEpisodeSelected(undefined);
    localStorage.clear();
  }

  async function checkPassword() {
    try {
      const res = await axios.post(lambdaEndpoint, {
        entry: document.getElementById("password-input").value,
      });
      if (res.status === 200) {
        setAuthenticated(true);
        return;
      }
      setAuthenticated(false);
    } catch (error) {
      console.log(error);
    } finally {
      document.getElementById("password-input").value = "";
    }
  }

  const passwordPage = (
    <PasswordPage
      keyPress={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          checkPassword();
        }
      }}
      checkAuth={() => checkPassword()}
    />
  );

  return !authenticated ? (
    passwordPage
  ) : !ready ? (
    <ClickAnywhere
      timeOfDay={timeOfDay}
      onCanvasClick={() => {
        transitionToStartPage();
        setFadeStyle("out");
        setFadeStyle("in");
      }}
    />
  ) : playing && scene.progression.length > 0 ? (
    <ProgressionPage
      timeOfDay={timeOfDay}
      text={text}
      choiceOneLabel={
        scene.progression[scene.progression.length - 1].choiceOne?.label
      }
      choiceTwoLabel={
        scene.progression[scene.progression.length - 1].choiceTwo?.label
      }
      choiceOneClick={handleChoiceOneClick}
      choiceTwoClick={handleChoiceTwoClick}
      actionClick={handleActionClick}
      progressing={progressing}
      actionMessage={actionMessage}
      fadeStyle={fadeStyle}
      hideChoiceButton={hideChoiceButton}
      slowDownEnter={handleSlowDownEnter}
      slowDownLeave={handleSlowDownLeave}
      speedUpEnter={handleSpeedUpEnter}
      speedUpLeave={handleSpeedUpLeave}
      slowDownClick={handleSlowDownClick}
      speedUpClick={handleSpeedUpClick}
      playSpeed={playSpeed}
      storyBoard={storyBoard}
      exitClick={resetVoyageState}
    />
  ) : (
    <StartPage
      ended={ended}
      endText={endText}
      timeOfDay={timeOfDay}
      fadeStyle={fadeStyle}
      episodeSelected={episodeSelected}
      storyBoard={storyBoard}
      onEpisodeSelected={(e) => {
        setEpisodeSelected(e.value);
        //any case/episode listed will be password protected otherwise it will default to authenticated.
        switch (e.value) {
          // case 3:
          //   setAuthenticated(false);
          //   break;
          default:
            setAuthenticated(true);
            break;
        }
      }}
      onPlayNowClick={async () => {
        audio.fadeAudioOut(backgroundInstances[0]);
        setFadeStyle("out");
        setScene(storyBoard.scenes[1]);
        setProgressing(true);
        setTimeout(() => {
          setFadeStyle("story-in");
          setPlaying(true);
        }, 3000);
      }}
    />
  );
}
