import styled from "styled-components";
import { StoryLogo } from "./StoryLogo";

const StartTitle = styled.h1`
  margin: 10px;
  letter-spacing: 1rem;
  font-weight: 900;
  position: relative;
  z-index: 1;
`;

export function StartHeader(props) {
  const { storyBoard } = props;
  return (
    <>
      <StoryLogo storyBoard={storyBoard} />
      <StartTitle>{storyBoard.title}</StartTitle>
    </>
  );
}
