import styled from "styled-components";
import { colors } from "../colors";

export const Input = styled.input`
  width: 200px;
  height: 30px;
  border: 2px solid ${colors.gray};
  border-radius: 6px;
  background-color: ${colors.almost_black};
  color: ${colors.white};
  font-size:16px;
  &:focus {
    outline: none;
}
`;
