import { Footer } from "../Footer";
import { Fade } from "../../styles/styled-components/Fade";
import { StartHeader } from "../StartHeader";
import { TypeWriterWrapper } from "../../styles/styled-components/TypeWriterWrapper";
import Typewriter from "typewriter-effect";
import { StartButtonContainer } from "../StartButton";
import { MainBody } from "../../styles/styled-components/MainBody";
import { Show } from "../Show";
import { EpisodeMenu } from "../EpisodeMenu";
import styled from "styled-components";

const MenuWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: 2rem;
  margin-left: -20px;
`;

export function StartPage(props) {
  const {
    onPlayNowClick,
    onEpisodeSelected,
    fadeStyle,
    timeOfDay,
    ended,
    endText,
    episodeSelected,
    storyBoard,
  } = props;

  return (
    <MainBody timeOfDay={timeOfDay}>
      <Fade duration={5} fadeStyle={fadeStyle}>
        <MenuWrapper>
          <Show when={!ended}>
            <EpisodeMenu onSelected={onEpisodeSelected} />
          </Show>
        </MenuWrapper>
        <StartHeader storyBoard={storyBoard} />
        <TypeWriterWrapper>
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .pauseFor(1000)
                .typeString(ended ? endText : storyBoard.tagline)
                .start();
            }}
          />
        </TypeWriterWrapper>
        <Show when={episodeSelected}>
          {!ended ? (
            <StartButtonContainer
              timeOfDay={timeOfDay}
              fadeStyle="in"
              delay={1}
              onPlayNowClick={onPlayNowClick}
              storyBoard={storyBoard}
            />
          ) : (
            <></>
          )}
        </Show>
      </Fade>
      <Footer />
    </MainBody>
  );
}
