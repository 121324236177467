import styled from "styled-components";
import { Show } from "../../components/Show";
import { colors } from "../colors";

const BaseButton = styled.button`
  background-color: ${colors.black};
  border: 1px solid transparent;
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: ${colors.white};
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0.75rem;
  margin: 1rem;
  text-align: center;
  text-decoration: none #6b7280 solid;
  text-decoration-thickness: auto;
  transition-duration: 0.2s;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  z-index: 3;
  :hover {
    background-color: ${colors.almost_black};
  }
  :focus {
    box-shadow: none;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
`;

const HiddenButton = styled(BaseButton)`
  visibility: hidden;
`;

const NightButton = styled(BaseButton)`
  background-color: ${colors.black};
  color: ${colors.white};
  @media only screen and (max-width: 475px) {
    background-color: ${colors.almost_black};
  }
  :hover {
    background-color: ${colors.almost_black};
  }
`;
const DayButton = styled(BaseButton)`
  background-color: ${colors.white};
  color: ${colors.black};
  @media only screen and (max-width: 475px) {
    background-color: ${colors.almost_white};
  }
  :hover {
    background-color: ${colors.almost_white};
  }
`;

export function Button(props) {
  const { timeOfDay, onClick, label, form, type } = props;
  return (
    <Show when={label} whenFalse={<HiddenButton />}>
      <Show when={timeOfDay === "night"}>
        <NightButton type={type} form={form} onClick={onClick}>
          {label}
        </NightButton>
      </Show>
      <Show when={timeOfDay === "day"}>
        <DayButton onClick={onClick}>{label}</DayButton>
      </Show>
    </Show>
  );
}
