import { Show } from "../Show";
import { ChoicesBox } from "../ChoicesBox";
import { MainBody } from "../../styles/styled-components/MainBody";
import { StoryText } from "../StoryText";
import Fade from "../../styles/styled-components/Fade";
import { useState } from "react";
import { StoryLogo } from "../StoryLogo";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { Explainer } from "../../styles/styled-components/Paragraph";
import { SpeedController } from "../SpeedController";
import { ExitButton } from "../../styles/styled-components/ExitButton";

const LogoWrapper = styled.div`
  width: 100vw;
  background: ${(props) =>
    props.timeOfDay === "night" ? colors.black : colors.white};
  padding: 0.5rem;
  position: absolute;
  bottom: 0.5rem;
  text-align: center;
`;

const SpeedControllerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: 2rem;
  margin-left: -20px;
`;

const ControlBar = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 160px;
`;

export function ProgressionPage(props) {
  const {
    text,
    choiceOneLabel,
    choiceTwoLabel,
    choiceOneClick,
    choiceTwoClick,
    progressing,
    timeOfDay,
    fadeStyle,
    hideChoiceButton,
    actionClick,
    actionMessage,
    slowDownClick,
    speedUpClick,
    storyBoard,
    playSpeed,
    exitClick,
  } = props;

  const [cursorStyle, setCurserStyle] = useState("default");
  const [speedControllerFade, setSpeedControllerFade] = useState("out");
  let pointerTimer;

  function handleMousePointerFade() {
    clearTimeout(pointerTimer);
    setCurserStyle("default");
    pointerTimer = setTimeout(() => {
      setCurserStyle("none");
    }, 3000);
  }

  return (
    <MainBody
      timeOfDay={timeOfDay}
      cursorStyle={cursorStyle}
      onMouseMove={handleMousePointerFade}
    >
      <ControlBar
        onMouseEnter={() => setSpeedControllerFade("in")}
        onMouseLeave={() => setSpeedControllerFade("out")}
      >
        <Fade fadeStyle={speedControllerFade} duration={2}>
          <ExitButton timeOfDay={timeOfDay} exitClick={exitClick} />
        </Fade>
        <SpeedControllerWrapper>
          <Fade fadeStyle={speedControllerFade} duration={2}>
            <SpeedController
              playSpeed={playSpeed}
              timeOfDay={timeOfDay}
              slowDownClick={slowDownClick}
              speedUpClick={speedUpClick}
            />
          </Fade>
        </SpeedControllerWrapper>
      </ControlBar>
      <Show
        when={
          progressing ||
          hideChoiceButton ||
          (text.text === "" && text.speaker === "")
        }
        whenFalse={
          <ChoicesBox
            timeOfDay={timeOfDay}
            choiceOneClick={choiceOneClick}
            choiceTwoClick={choiceTwoClick}
            choiceOneLabel={choiceOneLabel}
            choiceTwoLabel={choiceTwoLabel}
            text={text}
            fadeStyle={fadeStyle}
          />
        }
      >
        <Fade fadeStyle={fadeStyle}>
          <StoryText
            speaker={text.speaker}
            text={text.text}
            timeOfDay={timeOfDay}
          />
        </Fade>
      </Show>
      <LogoWrapper {...props}>
        <Explainer onClick={actionClick}>{actionMessage}</Explainer>
        <StoryLogo size="50px" storyBoard={storyBoard} />
      </LogoWrapper>
    </MainBody>
  );
}
