import styled from "styled-components";
import { Explainer } from "../styles/styled-components/Paragraph";
import { SpeedArrow } from "../styles/styled-components/SpeedArrow";

const Controller = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -35px;
`;

function getText(playSpeed) {
  let text = "";
  switch (playSpeed) {
    case "slow":
      text = "Slow";
      break;
    case "normal":
      text = "Normal Speed";
      break;
    case "fast":
      text = "Fast";
      break;
    default:
      break;
  }
  return text;
}

export function SpeedController(props) {
  const { playSpeed, timeOfDay, slowDownClick, speedUpClick } = props;
  return (
    <>
      <Wrapper>
        <Controller>
          <SpeedArrow
            type={"slow-down"}
            timeOfDay={timeOfDay}
            slowDownClick={slowDownClick}
          />
          <SpeedArrow
            type={"speed-up"}
            timeOfDay={timeOfDay}
            speedUpClick={speedUpClick}
          />
        </Controller>
        <Explainer>{getText(playSpeed)}</Explainer>
      </Wrapper>
    </>
  );
}
