import styled from "styled-components";
import voyageLogo from "../assets/images/voyage-logo.png";
import { colors } from "../styles/colors";

const Logo = styled.img`
  width: 200px;
  filter: ${colors.gray}; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
`;

export function VoyageLogo() {
  return (
    <>
      <Logo src={voyageLogo} alt="voyage logo" />
    </>
  );
}
