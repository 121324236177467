import styled from "styled-components";
import { runtimeEstimate } from "../story/DurationEstimator";
import { Button } from "../styles/styled-components/Button";
import Fade from "../styles/styled-components/Fade";
import { Explainer } from "../styles/styled-components/Paragraph";

const ButtonContainer = styled.div`
  margin-top: 2rem;
  margin-botton: 0;
  width: 100%;
`;

const TextContainer = styled.div`
  margin-top: -10px;
`;

const HeadphonesContainer = styled.div`
  margin-top: 30px;
`;

export function StartButtonContainer(props) {
  const { fadeStyle, delay, onPlayNowClick, timeOfDay, storyBoard } = props;
  return (
    <Fade fadeStyle={fadeStyle} delay={delay} duration={5}>
      <ButtonContainer>
        <Button
          timeOfDay={timeOfDay}
          onClick={onPlayNowClick}
          label={storyBoard.startButtonLabel}
        />
        <TextContainer>
          <Explainer>{runtimeEstimate(storyBoard)}</Explainer>
        </TextContainer>
      </ButtonContainer>
      <HeadphonesContainer>
        <Explainer>{`( headphones highly recommended )`}</Explainer>
      </HeadphonesContainer>
    </Fade>
  );
}
